// $primary: #a51616;
$primary: teal; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;


.logo {
  max-width: 350px;
  width: 100%;

  @media (max-width: 991px) {
  	max-width: 275px;
  }

  @media (max-width: 767px) {
    max-width: 250px;
  }
}

.navbar {
  background: -webkit-linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), rgba(255, 255, 255, 0.55) url("../img/navheader.jpg") no-repeat center;
  background: linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.85)), rgba(255, 255, 255, 0.55) url("../img/navheader.jpg") no-repeat center;
  background-size: cover;
  border: 0;
}

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
  text-align: center;
  height: 40px;
  background-color: white;
  font-size: 20px;
  font-family: 'Oswald', sans-serif;
  padding-left: 20px;
  margin-right: 5px;
  margin-top: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;


		@media (max-width: 767px) {
			margin-top: 2px;
			padding-left: 15px;
		    height: 35px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

@mixin btn3 {
	display: inline-block;
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	margin-top: 10px;
	padding: 1em 2em;

	&:hover {
		text-decoration: none;
		background: #a7aa2d;
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

a.btn3 {
	@include btn3;
}

.modal-dialog {
	max-width: 300px;
	z-index: 0;
	text-align: center;
	margin: 6em auto;
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.header {
  background: -webkit-linear-gradient(rgba(45, 185, 220, 0.85), rgba(255, 255, 255, 0.85)), rgba(0, 0, 0, 0.55) url("../img/fairyheader.jpg") no-repeat center;
  background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(45, 185, 220, 0.85)), rgba(0, 0, 0, 0.55) url("../img/fairyheader.jpg") no-repeat center;
  background-size: cover;
  height: 100vh;

  @media (max-width: 767px) {
  	height: auto;
  	background-position: 50% 0%;
  }
}

.fairytale {
  color: white;
  font-size: 80px;
  font-family: 'Great Vibes', cursive;
  text-align: center;

  @media (max-width: 767px) {
  	font-size: 60px;
  }
}

.icontext {
  font-family: 'Fanwood Text', serif;
  font-size: 40px;
  color: white;
  text-align: center;
}

.iconp {
  color: white;
}

.fantasybg {
  background: -webkit-linear-gradient(rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.35)), rgba(255, 255, 255, 0.35) url("../img/fantasybg.jpg") no-repeat center;
  background: linear-gradient(rgba(0, 0,0, 0.45), rgba(0, 0, 0, 0.35)), rgba(0, 0, 0, 0.35) url("../img/fantasybg.jpg") no-repeat center;
  background-position: 30% 20%;
  padding: 60px 0;
  background-size: cover;
}

.icon1 {
  margin: 0 auto;
}

.icon2 {
  margin: 0 auto;
}

.icon3 {
  margin: 0 auto;
}

.icon4 {
  margin: 0 auto;
}

.browsebutton {
	text-align: center;
	display: block;
}

.newchapter {
	background-image: url('../img/chapter.jpg');
	background-size: cover;
	background-position: 10% 70%;
	padding: 80px;

	@media (max-width: 520px) {
		background-position: 50% 10%;
	}
}

.col-sm-6 {
	padding-right: 0px;
	padding-left: 0px;
}

.headline {
	color: teal;
	font-family: 'Great Vibes', cursive;
	font-size: 80px;
	text-align: center;

	@media (max-width: 767px) {
		font-size: 60px;
	}

	@media (max-width: 520px) {
		font-size: 40px;
	}
}

.container-fluid {
	padding-left: 0px;
	padding-right: 0px;
}

.fixed-nav {
	position: fixed;
	z-index: 2;
	width: 100%;
	top: 0;
}

.middle {
	margin-top: 80px;

	@media (max-width: 990px) {
		margin-top: 0;
	}
}